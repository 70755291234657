import React from 'react'

const Footer = () => {
    return (
        <>

            <footer className="footer-area">
                <div className="footer-widget">
                    <div className="container">
                        <div className="row footer-widget-wrapper pt-120 pb-70">
                            <div className="col-md-6 col-lg-6">
                                <div className="footer-widget-box about-us">
                                    <a href="#" className="footer-logo">
                                        <img src="/Almora-log.jpg" style={{ height: '80px', width: '100px' }} alt="logo" />
                                    </a>
                                    <p className="mb-3">
                                        Setup and operating from Uk , Uk  Travels is one of the most prominent tour operators in India.
                                    </p>
                                    <ul className="footer-contact">
                                        <li><a href="tel:9084488229"><i className="far fa-phone-volume"></i> +91 9084488229</a></li>
                                        <li>
                                            <a href="mailto:Uk951825@gmail.com">
                                                <i className="far fa-envelopes"></i>
                                                <span> &nbsp; Uk951825@gmail.com</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="mailto:Uk951825@gmail.com">
                                                <i className="far fa-location-dot"></i>
                                                <span>  &nbsp; NTD Rd, Darbari Nagar, Talla Cheena Khan, Almora, Uttarakhand 263601</span>
                                            </a>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <div className="footer-widget-box list">
                                    <h4 className="footer-widget-title">Quick Links</h4>
                                    <ul className="footer-list">
                                        <li><a href="#about-us"><i className="fas fa-caret-right"></i> About Us</a></li>
                                        <li><a href="#Tour-Packages"><i className="fas fa-caret-right"></i> Tour Packages</a></li>
                                        <li><a href="#features"><i className="fas fa-caret-right"></i> Our Feature</a></li>

                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <div className="footer-widget-box list">
                                    <h4 className="footer-widget-title">Support Center</h4>
                                    <ul className="footer-list">

                                        <li><a href="#book"><i className="fas fa-caret-right"></i> Book A Car</a></li>
                                        <li><a href="#contact"><i className="fas fa-caret-right"></i> Contact Us</a></li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 align-self-center">
                                <p className="copyright-text">
                                    &copy; Copyright <span id="date"></span> <a href="#"> UK Travels </a> All Rights Reserved.
                                </p>
                            </div>
                            <div className="col-md-6 align-self-center">
                                <ul className="footer-social">
                                    <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                    <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                    <li><a href="#"><i className="fab fa-linkedin-in"></i></a></li>
                                    <li><a href="#"><i className="fab fa-youtube"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer